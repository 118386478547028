
import { defineComponent } from "vue";
import ParametroColumn from "@/util/table-columns/ParametroColumn";
import ParametroForm from "@/components/admin/redacoes/parametros/ParametroForm.vue";
import useList from "@/modules/useList";

export default defineComponent({
  components: { ParametroForm },
  setup() {
    const {
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
    } = useList("parametros", ParametroColumn);

    return {
      rows,
      loading,
      filter,
      columns,
      list,
      modalForm,
      openModalForm,
      formatData,
    };
  },
});
