
import { defineComponent } from "vue";
import { requiredField } from "@/util/form-rules";
import FormLoading from "../../../layout/FormLoading.vue";
import useForm from "@/modules/useForm";
import Parametro from "@/interfaces/Parametro";

interface Form {
  titulo: string;
  descricao: string;
}

const baseForm: Form = {
  titulo: "",
  descricao: "",
};

export default defineComponent({
  components: { FormLoading },
  setup(props, { emit }) {
    const { show, form, row, loading, submit, afterSubmit } = useForm(
      "parametros",
      "parametros",
      "o",
      baseForm,
      emit,
      "parametros"
    );

    const open = (data: Parametro | null): void => {
      if (data) {
        row.value = JSON.parse(JSON.stringify(data));
        if (row.value?.id) {
          form.value = {
            titulo: row.value.titulo,
            descricao: row.value.descricao,
          };
        } else {
          form.value = JSON.parse(JSON.stringify(baseForm));
        }
      }
      show.value = true;
    };

    return {
      show,
      open,
      form,
      row,
      requiredField,
      loading,
      submit,
      afterSubmit,
    };
  },
});
