import Parametro from "@/interfaces/Parametro";

const ParametroColumn = [
  {
    name: "actions",
    align: "center",
    label: "Ações",
    field: "",
    style: "width: 100px",
    sortable: false,
  },
  {
    name: "titulo",
    align: "left",
    label: "Título",
    field: "titulo",
    sortable: true,
  },
  {
    name: "descricao",
    align: "left",
    label: "Descricao",
    field: "descricao",
    sortable: true,
  },
  {
    name: "created_at",
    align: "center",
    label: "Data Cadastro",
    field: (row: Parametro) => row.created_at,
    sortable: true,
  },
];

export default ParametroColumn;
